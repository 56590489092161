/* eslint-disable jsx-a11y/aria-role */
import React, { ReactNode, useEffect } from 'react';
import {
  useSelector, shallowEqual, useDispatch,
} from 'react-redux';
import { Circles } from 'react-loading-icons';
import { refreshTokenCustomer } from '../redux/actions/customer/auth';
import { refreshTokenDriver } from '../redux/actions/driver/auth';

const loading = (
  <div>
    <main>
      <div style={{
        width: '100%',
        height: '50vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
      }}
      >
        <Circles fill="#06BCEE" />
      </div>
    </main>
  </div>
);

interface RefreshTokenProps {
  children: ReactNode
}

const RefreshToken = ({ children }: RefreshTokenProps) => {
  const dispatch = useDispatch();
  const authLoadingCustomer = useSelector(
    (state: any) => state.authCustomer.authLoading, shallowEqual,
  );
  const authLoadingDriver = useSelector(
    (state: any) => state.authDriver.authLoading, shallowEqual,
  );

  const authLoaded = !authLoadingCustomer && !authLoadingDriver;

  useEffect(() => {
    const refreshToken = localStorage.getItem('refresh_token_customer');
    const param = {
      refresh_token: refreshToken,
    };
    dispatch(refreshTokenCustomer(param));
  }, [dispatch]);

  useEffect(() => {
    const refreshToken = localStorage.getItem('refresh_token_driver');
    const param = {
      refresh_token: refreshToken,
    };
    dispatch(refreshTokenDriver(param));
  }, [dispatch]);

  return (
    !authLoaded
      ? loading
      : (
        <>
          {children}
        </>
      )
  );
};

export default RefreshToken;
